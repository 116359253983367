


















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { linkProps, useLink } from '../Link/Link.hooks'
import { themeClassRegistry } from '../Button/Button.config'
import { LinkProps } from '../Link'
import { variantRegistry } from '../Link/Link.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const ButtonLink = defineComponent({
  name: 'ButtonLink',
  props: linkProps,
  setup (props: LinkProps) {
    return useLink(props, getCurrentInstance(), themeClassRegistry, variantRegistry)
  }
})

export default ButtonLink
